/* src/styles/fonts.css */

:root {
  --font-family:  'Inter', sans-serif;
  --font-weight-black: 900;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-weight-light: 300;

  --font-style-normal: normal;
  --font-style-italic: italic;

  --font-display: swap;

  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-large: 18px;

  --line-height-small: 1.4;
  --line-height-medium: 1.6;
  --line-height-large: 1.8;
}

:where(.css-dev-only-do-not-override-11ofxie)[class^="ant-layout"], :where(.css-dev-only-do-not-override-11ofxie)[class*=" ant-layout"]{
  font-family: 'Lato', sans-serif;
}

body {
  font-family: var(--font-family) !important;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
}

h1 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
}