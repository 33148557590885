@function pxToRem($size) {
  $remSize: $size / 16; // Assuming a base font size of 16px
  @return #{$remSize}rem;
}

@mixin fontSize($size, $useRem: false) {

  font-size: pxToRem($size);
  @if $useRem {
    font-size: $size + px;
  }
}
// .element {
//     @include fontSize(16);          // Font size in pixels
//     @include fontSize(1.6, true);    // Font size with rem units
//   }


// _mixins.scss

// Example mixin for padding with optional rem units
@mixin padding($top: null, $right: null, $bottom: null, $left: null, $useRem: false) {
    @if $top {
      padding-top: $top + px;
      @if $useRem {
        padding-top: $top + rem;
      }
    }
  
    @if $right {
      padding-right: $right + px;
      @if $useRem {
        padding-right: $right + rem;
      }
    }
  
    @if $bottom {
      padding-bottom: $bottom + px;
      @if $useRem {
        padding-bottom: $bottom + rem;
      }
    }
  
    @if $left {
      padding-left: $left + px;
      @if $useRem {
        padding-left: $left + rem;
      }
    }
  }

//   .element {
//     @include padding(10);                  // Padding on all sides in pixels
//     @include padding(10, 20);              // Padding top and right in pixels
//     @include padding(10, 20, 30);          // Padding top, right, and bottom in pixels
//     @include padding(10, 20, 30, 40);      // Padding top, right, bottom, and left in pixels
  
//     @include padding(1, 2, 3, 4, true);   // Padding with rem units
//   }
  
@mixin paddingLeft($size, $useRem: false) {
    padding-left: $size + px;
  
    @if $useRem {
      padding-left: $size + rem;
    }
  }

@mixin paddingRight($size, $useRem: false) {
    padding-right: $size + px;
  
    @if $useRem {
      padding-right: $size + rem;
    }
  }
  
  // Example mixin for padding-top with optional rem units
  @mixin paddingTop($size, $useRem: false) {
    padding-top: $size + px;
  
    @if $useRem {
      padding-top: $size + rem;
    }
  }
  
  // Example mixin for padding-bottom with optional rem units
  @mixin paddingBottom($size, $useRem: false) {
    padding-bottom: $size + px;
  
    @if $useRem {
      padding-bottom: $size + rem;
    }
  }

  //  @include paddingRight(20);      


  @mixin marginLeft($size, $useRem: false) {
    margin-left: $size + px;
  
    @if $useRem {
      margin-left: $size + rem;
    }
  }

  @mixin marginRight($size, $useRem: false) {
    margin-right: $size + px;
  
    @if $useRem {
      margin-right: $size + rem;
    }
  }
  
  // Example mixin for margin-top with optional rem units
  @mixin marginTop($size, $useRem: false) {
    margin-top: $size + px;
  
    @if $useRem {
      margin-top: $size + rem;
    }
  }
  
  // Example mixin for margin-bottom with optional rem units
  @mixin marginBottom($size, $useRem: false) {
    margin-bottom: $size + px;
  
    @if $useRem {
      margin-bottom: $size + rem;
    }
  }
  @mixin breakpoint($breakpoint) {
    @if $breakpoint==small-phone {
        @media (max-width: 380px) {
            @content;
        }
    }

    @if $breakpoint==phone {
        @media (max-width: 576px) {
            @content;
        }
    }

    @if $breakpoint==large-phone {
        @media (max-width: 678px) {
            @content;
        }
    }

    @if $breakpoint==tablet {
        @media (max-width: 768px) {
            @content;
        }
    }

    @if $breakpoint==tablet-large {
        @media (max-width: 991px) {
            @content;
        }
    }

    @if $breakpoint==tablet-land {
        @media (max-width: 1024px) {
            @content;
        }
    }

    @if $breakpoint==min-desktop {
        @media (max-width: 1199px) {
            @content;
        }
    }

    @if $breakpoint==apple-laptop {
        @media (max-width: 1280px) {
            @content;
        }
    }

    @if $breakpoint==desktop {
        @media (max-width: 1366px) {
            @content;
        }
    }

    @if $breakpoint==apple-desktop {
        @media (max-width: 1440px) {
            @content;
        }
    }

    @if $breakpoint==large-desktop {
        @media (max-width: 1600px) {
            @content;
        }
    }
     @if $breakpoint==larger-desktop {
       @media (max-width: 1720px) {
         @content;
       }
     }
      @if $breakpoint==too-large-desktop {
      @media (max-width: 1800px) {
        @content;
      }
      }

    @if $breakpoint==large-then-desktop {
        @media (max-width: 1920px) {
            @content;
        }
    }
}

@mixin ellipsis($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

@mixin fontSize($value, $important: false) {
    @if ($important) {
        font-size: ($value / 16) + rem !important;
    } @else {
        font-size: ($value / 16) + rem;
    }
}




@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@function toRem($value) {
    $remValue: ($value / 16) + rem;
    @return $remValue;
}
