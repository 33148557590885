.customer-data-base {
  .add-btn {
    padding: 0 12px;
    font-family: "Poppins", sans-serif;
    font-size: clamp(14px, 1.5vw, 20px);
    font-weight: 500;
    height: 48px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bd44ca;
    color: #fff;
    border-radius: 25px;
    font-size: 1rem;
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: initial;
    background: #bd44ca;
  }
  .ant-table-content {
    height: calc(100vh - 400px);
    .ant-table-cell {
      padding: 16px 12px;
    }
  }
  .plan-names {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
  }
  p.customer-name {
    max-width: 250px;
  }
  .ant-select-selection-item,
  .search-input {
    color: #1d1d24;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500 !important;
    white-space: normal !important;
  }
  .search-input {
    color: #333;
    font-size: 16px;
  }
  .ant-select .ant-select-arrow {
    margin-right: 0px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
  }
  .ant-select-selector {
    min-height: 20px !important;
  }
  @media (max-width: 1300px) {
    .add-btn {
      height: 37px;
    }
  }
}
.ant-dropdown-menu-item .csv-upload-options {
  align-items: center;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: clamp(14px, 1.5vw, 20px);
  font-weight: 500;
  height: 50px;
  padding-left: 6px;
  padding-right: 6px;
}
.ant-dropdown-menu-item .csv-upload-options:hover {
  background-color: #f0f8ff;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 6px !important;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 4px;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
  background-color: initial;
}

// Custom date picker
.custom-date-range-picker {
  border: none;
  padding: 0 !important;
  height: 24px !important;
  width: 24px !important;
  cursor: pointer;
}

.custom-date-range-picker .ant-picker-input {
  width: 20px;
  transition: all 0.3s;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-picker .ant-picker-input > input:placeholder-shown {
  box-shadow: none;
  opacity: 0;
}
/* Hide the separation arrow between dates */
.custom-date-range-picker .ant-picker-range-separator {
  display: none;
}

/* Only show calendar icon initially */
.custom-date-range-picker:not(.ant-picker-focused):not(:hover) .ant-picker-suffix {
  margin-right: 0;
}
