.ant-layout-sider {
  min-width: 290px !important;
  background-color: #fff !important;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 -4px 8px rgba(0, 0, 0, 0);
  .ant-layout-sider-trigger {
    display: none;
  }
  .menu-btn {
    right: -15px;
    bottom: 6px;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(229 230 232);
    transition: all 0.3s ease;
  }
  .menu-btn:hover {
    background-color: rgb(216, 216, 219);
  }
  .ant-menu-item-icon {
    font-size: 19px !important;
  }
  .ant-layout-sider-children {
    padding: 15px 10px;
    .sidebar-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 20px;
    }
  }
  .ant-menu {
    background-color: #fff !important;
    padding-top: 2rem;
  }
  .ant-menu-item {
    border-radius: 6px;
    min-height: 43px;
  }
  .ant-menu-item:not(.ant-menu-item-selected):not(.permission-disabled-menu) {
    transition: background-color 0.3s ease-in !important;
  }
  .ant-menu-title-content {
    line-height: 1;
    font-size: 16px;
    color: #333;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  .ant-menu-item-selected {
    background-color: #bd44ca;
    transition: background-color 0.2s ease-in !important;

    a {
      color: #fff !important;
      transition: background-color 0.2s ease-in !important;
    }
    .ant-menu-item-icon {
      color: #fff !important;
      transition: background-color 0.2s ease-in !important;
    }
  }

  .activeCollapsed {
    width: 80px !important;
  }
  .disabled-menu {
    a,
    .ant-menu-item-icon {
      cursor: not-allowed !important;
    }
    a,
    svg {
      color: #a9a9a9;
    }
  }
  .ant-menu-item-active.disabled-menu:hover {
    a {
      color: #a9a9a9;
    }
  }
}
.activeCollapsed {
  min-width: 80px !important;
  .ant-menu-item {
    display: flex;
    align-items: center;
  }
}
