.ant-select {
  &-selector {
    border: 1px solid #d0d5dd !important;
  }

  &-selection-item {
    font-size: 16px;
    white-space: normal !important;
    color: #1d1d24;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  &-btn {
    font-weight: 600;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    height: 48px;
    color: #fff;
  }

  &-selection-overflow {
    &-item {
      .ant-select-selection-item {
        background: #efedff !important;
        height: 38px;
        display: flex;
        align-items: center;

        &-remove {
          display: none;
        }
      }
    }
  }
}

.ant-picker-input {
  font-size: 20px;

  span {
    color: #545263 !important;
  }

  font-family: "Poppins",
  sans-serif;
}

.__custom-select {
  .ant-select {
    &-selector {
      background-color: #5441da !important;
      color: #fff;
    }

    &-arrow {
      img {
        filter: brightness(100%) contrast(0%);
      }
    }
  }

  .ant-select-selection-item {
    color: #fff !important;
  }
}

.ant-pagination {
  margin: 15px 0 0 0 !important;
  display: none;
}

.customer-table {
  th {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
  }

  .ant-table-content {
    height: 360px;
    transition: all 0.4s ease;
    overflow-y: scroll;
  }

  .status {
    span {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 10px;
      margin-right: 10px;
    }

    &.active {
      color: #027a48;

      span {
        background-color: #027a48;
      }
    }

    &.implementation {
      color: #ff9b25;

      span {
        background-color: #ff9b25;
      }
    }

    &.inactive {
      color: #79797a;

      span {
        background-color: #79797a;
      }
    }
  }
}

.application-chart {
  .apexcharts-text tspan {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
}

.apexcharts-xaxis {
  display: none !important;
}

// .ant-table-column-sorter {
//   display: none;
// }

.ant-table-thead {
  position: sticky;
  top: -10px !important;
  z-index: 9;
}

// modal style
.add-user {
  .modal-footer {
    border-top: 1px solid #efefef;
    border-radius: 8px;
  }

  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }
  }
}

.ant {
  &-table {
    &-column-title {
      font-size: 1rem;
    }

    &-cell {
      font-size: 1rem !important;
    }
  }

  &-table-row-selected>.ant-table-cell {
    background: transparent !important;
  }

  &-modal-confirm-btns {
    .ant-btn {
      background-color: #5441da !important;
    }
  }

  &-pagination-options {
    position: absolute;
    left: 0;
    margin: 0;

    .ant-select-selection-item {
      font-size: 15px !important;
    }
  }

  &-spin-dot-item {
    background-color: #171717 !important;
  }

  &-switch {
    background-color: #d9d9d9 !important;

    &-checked {
      background-color: #5441da !important;
    }
  }

  &-progress-text {
    display: none !important;
  }

  &-steps {
    &-item-active {
      .ant-steps-item-icon {
        background-color: #4cbb3a !important;
        border-color: #4cbb3a !important;
        margin: 0 !important;
      }
    }

    &-icon {
      display: none;
    }

    &-item {
      padding: 0 !important;

      &-finish {
        .ant-steps-item-icon {
          background-color: #4cbb3a !important;
          border-color: #4cbb3a !important;
          margin: 0;
        }
      }

      &-wait {
        .ant-steps-item-icon {
          margin: 0;
        }
      }

      &-title {
        padding-inline-end: 0px !important;

        &::after {
          border-width: 1.3px !important;
        }
      }
    }
  }

  &-table-filter-dropdown-btns {
    .ant-btn-primary {
      background-color: #5441da;
    }
  }

  &-radio {
    &-inner {
      border-color: #5441da;
      background-color: #fff !important;
      width: 22px !important;
      height: 22px !important;
      border-width: 2px !important;

      &::after {
        background: #5441da !important;
        width: 28px !important;
        height: 28px !important;
        margin-block-start: -14px !important;
        margin-inline-start: -14px !important;
      }
    }
  }
}

// custum component scss
.custom-select {
  width: 24px !important;
}

// notification

.ant-notification {
  &-notice-success {
    background: #f2faf5 !important;

    .ant-notification {
      &-notice-message {
        color: black !important;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600 !important;
      }

      &-notice-description {
        color: black !important;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        font-weight: 600 !important;
      }
    }
  }

  &-notice-close {
    top: 10px !important;
    width: 0 !important;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.custom {
  &-chevron {
    width: 24px !important;
  }

  &-toogle {
    .ant {
      &-switch {
        background-color: #d9d9d9 !important;

        &-checked {
          background-color: #41da91 !important;
        }
      }
    }
  }
}

.upload-file {
  .anticon {
    &-eye {
      display: none;
    }
  }
}

.blockAndDeleteButton {
  background-color: transparent !important;
  width: 22px !important;
  height: 22px !important;
  display: flex;
  justify-content: center;
  align-items: center;
      
}

.rc-virtual-list {
  .ant-select-item-option-content {
    width: 30px;
    min-height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;

    input {
      min-height: 40px;
      cursor: pointer;
      margin-right: 10px;
    }

    .dropdown-list {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 56px;
      }
    }
  }

  .ant-select-item-option {
    padding: 0px 12px !important;
    align-items: center;

    &-state {
      display: none !important;
    }
  }
}

.rc-virtual-list-holder {
  overflow-x: hidden;
}

.unused {
  color: gray !important;
}

.custom-err {
  position: absolute !important;
  bottom: -30px;
}

.searchApps {
  .ant-select-selector {
    overflow-y: auto;
  }

  .ant-select-selection-search {
    width: 100% !important;
  }
}

.myAppsTextArea {
  height: 148px !important;
}

.ant-dropdown {
  &.ant-dropdown-placement-bottom {
    min-width: 210px !important;

    @media (max-width: 1600px) {
      li {
        font-size: 12px !important;
      }
    }
  }
}

.apexcharts-legend-text {
  font-size: 1rem !important;
}

.ssoDropdown {
  .ant-select-arrow {
    display: none;
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        padding-left: 12px;
      }
    }
  }
}

.uploadError {
  .ant-tooltip-arrow {
    display: none !important;
  }

  .ant-tooltip-inner {
    display: none !important;
  }

  .ant-upload-list-item-error {
    border-color: transparent !important;
  }
}

.ant-checkbox {
  &:hover {
    background-color: transparent !important;
  }

  &-checked {
    .ant-checkbox-inner {
      border: 1px solid #7f56d9 !important;
    }
  }

  &-indeterminate {
    .ant-checkbox-inner {
      border: 1px solid #7f56d9 !important;

      &::after {
        height: 2px !important;
      }
    }
  }
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  border-radius: 5px !important;
  border: 1px solid #d0d5dd !important;
  background-color: #fff !important;

  &::after {
    height: 11px !important;
    border: 2px solid #7f56d9 !important;
    border-top: 0 !important;
    border-left: 0 !important;
  }
}

.permission-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;

  .material-icons-outlined {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .cards {
    background: transparent !important;

    .ant-image-img {
      opacity: 0.5;
    }

    p {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.permission-disabled-switch {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #d9d9d9 !important;
  box-shadow: none;
}

.permission-disabled-userEdit {
  opacity: 0.6;
  cursor: not-allowed;

  .ant-image-img {
    opacity: 0.6;
  }
}

.permission-disabled-menu {
  cursor: not-allowed;

  .ant-menu-title-content {
    color: rgba($color: #fff, $alpha: 0.5);
  }

  .ant-menu-item-icon {
    opacity: 0.5;
  }
}

.permission-disabled-text {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}

.permission-modal {
  width: 596px;

  p {
    color: #101828;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
}

.custom-radio {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: inherit;

  .ant-radio-wrapper {
    span {
      display: -webkit-box;
      max-width: 150px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.ant-table-filter-column {
  display: flex;
  justify-content: space-evenly !important;
}

.loginThemeText {
  .ant-select-selection-item {
    font-size: 18px;
    line-height: 1.2 !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.show-notification {
  &.unauthorized {
    .ant-modal-close {
      display: none !important;
    }

    font-family: "Inter",
    sans-serif;

    p {
      margin-top: 0.3rem;
      font-weight: 400;
      line-height: 1.4;
    }
  }
}

.disabled.user-status {
  text-transform: capitalize;
}

.ant-picker-footer {
  .ant-picker-ranges {
    .ant-picker-ok {
      .ant-btn {
        background-color: #5441da !important;
        color: #ffff;
      }
    }
  }
}

.bar-chart .apexcharts-bar-area .apexcharts-bar {
  rx: 9px;
  ry: 9px;
}

.btn-disabled {
  background-color: unset !important;
}

.ant-picker.ant-picker-outlined {
  height: 54px;
  width: 100%;
  padding-top: 15px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.version {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
  @apply font-Inter;
  color: #aaa;
  font-size: 15px;
  font-weight: 500;
}

.decrement-gray {
  color: #e3e3e3;
}

.restrictionTableContainer {
  height: calc(100vh - 20px) !important;
}

._success_message {
  color: #027a48;
}
.Restrictions{
  justify-content: center !important;
}
.credentials-table {
  .ant-table {
    &-filter-column {
      justify-content: start !important;
      width: 46px;
      flex: 0 !important;
    }
  }

  .ant-table-filter-trigger:hover {
    background-color: unset;
  }
}
.ant-select-selection-search{
 width: 460px !important;
}
.users-total{

  font-family: 'Inter',sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}
