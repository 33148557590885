@media (min-width:1720px) and (max-width:1820px){

    .single-user{
        input{
            height: 48px;
        }
    }
}
@media (min-width: 1580px) and (max-width: 1719px) {
 
    // admin dashboard
    .dashboard{
        &-container{
            .card{
                &-title{
                    @include fontSize(22);
                }
            }
        }
    }

}

@media (min-width: 1440px) and (max-width: 1579px) {
  
   .dashboard {
       &-container {
           .card {
               &-title {
                   @include fontSize(20);
               }
           }
       }
   }
}
@media (min-width: 1381px) and (max-width: 1439px) {
  
           // admin dashboard
           .dashboard {
               &-container {
                   .card {
                       &-title {
                           @include fontSize(20);
                       }
                   }
               }
           }

}
@media (min-width: 768px) and (max-width: 1380px) {
  
           // admin dashboard
           .dashboard {
               &-container {
                   .card {
                       &-title {
                           @include fontSize(18);
                       }
                   }
               }
           }
}
@media (max-width: 1023px){
.expanded-width {
            width: calc(100% - 80px);

    }

    .collapsed-width {
        width: calc(100% - 80px);
    }
        .ant-layout-sider-trigger {
            display: none !important;
        }
        .theme-container{
                        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
            padding: 0 20px;
        }
    
        .banner-container{
            display: none !important;
        }
                .app-theme .layout {
                    background-image: unset !important;
                }
}
@media (max-width: 1380px) and (max-height: 650px) {
    .clients.modal-fullScreen{
        .edit-basic{
            padding-top: 30px;
            .input-container{
                margin-bottom: 30px;
            }
        }
    }
.device-restriction{
    &.groups{
        .pt-10{
            padding-top: 1.5rem;
        }
    }
}

}
   