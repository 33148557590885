.hubspot-deal {
  .hubspot-form-wrapper {
    height: calc(100vh - 240px);
  }
  .label {
    position: absolute;
    top: -6px;
    background-color: #f7f7f7;
    z-index: 3;
    margin-left: 10px;
  }
  .field-label {
    color: #333;
    margin-bottom: 10px;
    // color: #969696;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  .ant-input {
    background-color: inherit !important;
  }
  .ant-input-outlined:hover {
    border-color: inherit;
    background-color: inherit;
  }
  .close-date {
    input {
      height: 100%;
      background: #fff !important;
      width: 100%;
      font-family: " Poppins", sans-serif;
      font-size: 16px;
    }
  }
  .contact {
    input {
      background: transparent !important;
    }
  }
  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: #333;
  }
  .cursor-not-allowed {
    span.ant-select-selection-item {
      cursor: not-allowed !important;
    }
  }
  .submit-btn {
    display: flex;
    cursor: pointer !important;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: 1px solid #bd44ca;
    background: #bd44ca;
    margin-left: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-family: " Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    height: 46px;
    width: 150px;
    transition: all 0.3s ease;
  }
  .submit-btn:hover {
    border: 1px solid #bd44ca !important;
    color: #fff !important;
    background: #e352f2 !important;
  }
}
.no-contacts-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #333;
  padding: 20px 0;
}
